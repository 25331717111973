const envApiBasePathMapping: Record<string, string> = {
  "https://stg-elections-v4-cms.news18.com": "https://stg-elections-v4-api.news18.com",
  "https://beta-elections-v4-cms.news18.com": "https://elections-v4-api.news18.com",
  "https://elections-v4-cms.news18.com": "https://elections-v4-api.news18.com",
}

export const getApiBasePath = (): string => {
  console.log(process.env.NODE_ENV, window.origin);
  if (process.env.NODE_ENV !== "production") return "http://localhost:3333"

  if (window && window.origin) {
    return envApiBasePathMapping[window.origin]
  }
  return "https://stg-elections-v4-api.news18.com"
}

export const API_URL = getApiBasePath();

const d = new Date();
const leapYear = d.getFullYear() % 4 === 0;
const noOfDaysInFeb = leapYear ? 29 : 28;
const _31Days = 31;
const _30Days = 30;
export const noOfDaysInMonth = [_31Days, noOfDaysInFeb, _31Days, _30Days, _31Days, _30Days, _31Days, _31Days, _30Days, _31Days, _30Days, _31Days];
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function getLast15Years() {
  const currentYear = new Date().getFullYear();
  const years: string[] = [];

  for (let i = 0; i < 30; i++) {
      years.push((currentYear - i).toString());
  }

  return years;
}

export const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
    },
  },
};
