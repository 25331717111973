import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderToggler,
  CNavLink,
  useColorModes,
  CHeaderNav,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilContrast,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilMoon,
  cilSun,
} from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import FormInput from 'src/components/FormInput/FormInput'
import { useForm } from 'react-hook-form'
import useAuthValue, { getAuthValue, setAuthValue } from 'src/modules/authModule/Hooks/useAuthValue'
import { queryClient } from 'src/utils/ReactQueryConfig'

const AppHeader = () => {
  const headerRef = useRef()
  const { language } = useAuthValue()
  const { colorMode, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

  useEffect(() => {
    setColorMode('light');
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })
  }, [])

  const { control, watch } = useForm({
    defaultValues: { language },
    mode: "onChange",
  });

  const languages = [
    { id: 'en', name: 'English' },
    { id: 'hi', name: 'हिन्दी (Hindi)' },
    { id: 'as', name: 'অসমীয়া (Assam)' },
    { id: 'mr', name: 'मराठी (Marathi)' },
    { id: 'bn', name: 'বাংলা (Bengali)' },
    { id: 'ur', name: 'اردو (Urdu)' },
    { id: 'pa', name: 'ਪੰਜਾਬੀ (Punjabi)' },
    { id: 'ta', name: 'தமிழ் (Tamil)' },
    { id: 'te', name: 'తెలుగు (Telugu)' },
    { id: 'or', name: 'ଓଡ଼ିଆ (Odia)' },
    { id: 'kn', name: 'ಕನ್ನಡ (Kannada)' },
    { id: 'gu', name: 'ગુજરાતી (Gujarati)' },
    { id: 'ml', name: 'മലയാളം (Malayalam)' },
  ];

  useEffect(() => {
    setAuthValue({
      ...getAuthValue(),
      language: watch('language')
    });
    queryClient.refetchQueries();
  }, [watch('language')])

  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          style={{ marginInlineStart: '-14px' }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="d-none d-md-flex">
          <CNavItem>
            <CNavLink to="/dashboard" as={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <FormInput
              className={"mx-3"}
              name={"language"}
              control={control}
              label={"Language"}
              type="select"
              inputGroup={false}
              inputProps={{
                showLabel: false,
                showSelectOption: true
              }}
              options={languages}
            />
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto">
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          {/* <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <CDropdown variant="nav-item" placement="bottom-end">
            <CDropdownToggle caret={false}>
              {colorMode === 'dark' ? (
                <CIcon icon={cilMoon} size="lg" />
              ) : colorMode === 'auto' ? (
                <CIcon icon={cilContrast} size="lg" />
              ) : (
                <CIcon icon={cilSun} size="lg" />
              )}
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                active={colorMode === 'light'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('light')}
              >
                <CIcon className="me-2" icon={cilSun} size="lg" /> Light
              </CDropdownItem>
              <CDropdownItem
                active={colorMode === 'dark'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('dark')}
              >
                <CIcon className="me-2" icon={cilMoon} size="lg" /> Dark
              </CDropdownItem>
              <CDropdownItem
                active={colorMode === 'auto'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('auto')}
              >
                <CIcon className="me-2" icon={cilContrast} size="lg" /> Auto
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li> */}
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CContainer className="px-4" fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
